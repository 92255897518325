import axios from "axios";

axios.defaults.timeout = 10000;
axios.defaults.withCredentials = true;

// http 請求攔截器
// axios.interceptors.request.use(
//   config => {
//     config.headers = {
//       "Content-Type": "application/json",
//       Authorization: store.state.local.token
//     };
//     return config;
//   },
//   err => {
//     return Promise.reject(err);
//   }
// );

export default axios;
