import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    screenWidth:document.body.clientWidth,
    screenHeight:document.body.clientHeight,
    showDrawer:false,
    msg: { show: false, text: "", color: undefined, timeout: undefined },
    showBuyDialog:false
  },
  mutations: {
    setScreenWidth(state,width){
      state.screenWidth = width;
    },
    setScreenHeight(state,height){
      state.screenHeight = height;
    },
    setShowDrawer(state,tf){
      state.showDrawer = tf;
    },
    setMsg(state, msgObj) {
      state.msg = msgObj;
    },
    setShowBuyDialog(state,tf){
      state.showBuyDialog = tf;
    }
  },
  actions: {
  },
  modules: {
  }
})
