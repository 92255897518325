<template>
  <router-view id="app"></router-view>
</template>

<script>
import store from "@/store";
import router from "@/router";

export default {
  name: "App",
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBackCheck, false);
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBackCheck, false);
  },
  methods: {
    goBackCheck() {
      if (store.state.showBuyDialog) {
        store.commit("setShowBuyDialog", false);
        router.go(1);
      }
    }
  }
};
</script>
